.staffsPage {
    font-family: 'Poppins';

    &__header {
        display: flex;
        &__header1 {
            &--text {
                color: #fff;
                margin-top: 150px;
                font-size: 70px;
                margin-left: 55px;
                letter-spacing: 3px;
            }
            &--img {
                margin-left: 55px;
                margin-top: 7%;
                width: 200px;
                height: 200px;
                border-radius: 10px;
            }
            display: flex;
            background-color: #061227;
            height: 400px;
            width: 65%;
        }
        &__header2 {
            background-color: #0d4194;
            height: 400px;
            width: 35%;
        }
    }
    &__title {
        margin-top: 2rem;
        margin-left: 2rem;
        color: #0d4194;
    }
    &__staffCards {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: auto;
        &__title {
            font-size: 18px;
        }
        margin-bottom: 50px;
    }
    .staffCard {
        color: #fff;
        display: grid;
        width: 250px;
        height: 235px;
        margin-left: 20px;
        margin-top: 80px;
        cursor: pointer;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.39);
        transition: 0.3s;
        font-family: 'Poppins';
        &__header {
            text-align: center;
            &--img {
                margin-top: -10px;
                width: 250px;
                height: 250px;
                border-radius: 10px;
            }
        }
        &__container {
            text-align: center;
            top: -260px;
            position: relative;
            width: 250px;
            height: 250px;
            opacity: 0;
            transition: 0.8s ease;
            background-color: #071833ca;
            border-radius: 10px;
            &:hover {
                opacity: 1;
            }
            &__overlay {
                margin-top: 30%;
                &--label {
                    text-transform: uppercase;
                    font-size: 15px;
                    top: 10px;
                }
                &--text {
                    font-size: 20px;
                    margin-bottom: 15px;
                    font-weight: 800;
                }
            }
        }
    }
}
@media screen and (max-width: 1100px) {
    .staffsPage {
        &__staffCards {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
@media screen and (max-width: 800px) {
    .staffsPage {
        &__staffCards {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}
@media screen and (max-width: 695px) {
    .staffsPage {
        text-align: center;
        &__header {
            &__header1 {
                &--text {
                    margin-top: 100px;
                    margin-right: 50px;
                }
                height: 350px;
                width: 100%;
            }
            &__header2 {
                display: none;
            }
        }
        &__staffCards {
            justify-items: center;
            grid-template-columns: repeat(1, 1fr);
            margin-right: 20px;
        }
    }
}
@media screen and (max-width: 1023px) {
    .staffsPage {
        text-align: center;
        &__header {
            &__header1 {
                &--img {
                    margin-left: auto;
                    margin-right: auto;
                    margin-top: 7%;
                    width: 160px;
                    height: 160px;
                    border-radius: 10px;
                }
                &--text {
                    margin-top: 10px;
                    margin-right: 50px;
                    font-size: 50px;
                }
                display: grid;
            }
        }
    }
}

.landingPage {
    font-family: 'Poppins';
    color: #212534;
}
/* scrollbar width */
::-webkit-scrollbar {
    width: 10px;
}
body {
    zoom: 82%;
}
/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #000000;
    border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.signUp {
    &__container {
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-family: 'Poppins';
        &--img {
            width: 200px;
            height: 200px;
            margin-top: 100px;
        }
    }
    .input_box {
        margin-bottom: -10px;
        label {
            color: #000000;
            font-size: 17px;
            margin-top: -25px;
        }
    }
    .selectInputBox {
        margin-bottom: -10px;
    }

    &__btn {
        margin-top: 13px;
        font-size: 17px;
        color: #fff;
        width: 100px;
        padding: 10px;
        background-color: transparent;
        border: none;
        font-weight: 600;
        cursor: pointer;
        text-align: center;
        border-radius: 10px;
        box-shadow: 0 0 40px 40px #0d4194 inset;
        font-family: 'Poppins';
        text-transform: uppercase;
        letter-spacing: 1.3px;
    }
    &__login {
        margin-left: 30px;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}

@media only screen and (max-width: 530px) {
    .signUp {
        &__modal {
            width: 300px;
            &--imgDiv {
                width: 250px;
            }
            .input_box {
                label {
                    font-size: 16px;
                }
            }
        }
    }
}

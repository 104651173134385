.header {
    background-repeat: no-repeat;
    background-size: cover;
    height: 750px;
    background-position: center center;
    background-attachment: fixed;
    &__container {
        display: flex;
        justify-items: center;
        max-width: 1250px;
        margin: auto;
        &--img {
            width: 40%;
            margin-top: 100px;
            margin-right: 40px;
            margin-left: 20px;
            max-height: 450px;
        }
        &--text {
            color: #fff;
            font-size: 40px;
            font-weight: 800;
            margin-top: 100px;
            width: 100%;
            margin-left: 20px;
            margin-right: 20px;
        }
        &--subText {
            color: #e9e9e9;
            font-size: 25px;
            margin-top: 30px;
            width: 100%;
            margin-left: 20px;
        }
        &--contactBtn {
            margin-top: 30px;
            margin-left: 20px;
            font-size: 22px;
            letter-spacing: 1px;
            font-family: 'Poppins';
            background-color: #0d4194;
            color: #fff;
            padding: 0.2rem 0.9rem;

            border: none;
            font-weight: 600;
            cursor: pointer;
            &:hover {
                background-color: #0d4194d5;
            }
        }
    }
}
@media screen and (max-width: 1222px) {
    .header {
        &__container {
            &--img {
                width: 40%;
            }
            &--text {
                font-size: 35px;
            }
            &--subText {
                font-size: 25px;
            }
        }
    }
}
@media screen and (max-width: 910px) {
    .header {
        height: 900px;
        text-align: center;
        &__container {
            &--img {
                display: none;
            }
            &--text {
                width: 90%;
                margin-left: 20px;
                margin-right: 0;
                margin-top: 20px;
            }
            &--subText {
                width: 90%;
                margin-left: 20px;
            }
            &--contactBtn {
                width: 30%;
                margin-left: 20px;
            }
        }
    }
}

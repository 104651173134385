.matchesPage {
    font-family: 'Poppins';
    &__header {
        display: flex;
        &__header1 {
            &--undefined,
            &--SchoolTournament {
                color: #fff;
                margin-top: 150px;
                font-size: 70px;
                margin-left: 55px;
                letter-spacing: 3px;
            }
            &--LeagueTournament,
            &--KnockoutTournament {
                color: #fff;
                margin-top: 6%;
                font-size: 50px;
                margin-left: 55px;
                letter-spacing: 3px;
                margin-right: 10px;
            }
            &--img {
                margin-left: 55px;
                margin-top: 7%;
                width: 200px;
                height: 200px;
                border-radius: 10px;
            }
            display: flex;
            background-color: #061227;
            height: 400px;
            width: 65%;
        }
        &__header2 {
            background-color: #0d4194;
            height: 400px;
            width: 35%;
        }
    }
    &__matchSelect select {
        background-color: #071833;
        color: #fff;
        border: none;
        font-size: 18px;
        border-radius: 5px;
        padding: 0.4rem 0.5rem;
        margin: 20px;
        font-family: 'Poppins';
        justify-content: center;
        cursor: pointer;
        text-transform: uppercase;
    }

    &__matchCards {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: auto;
    }

    &__matchPageSelect {
        text-align: center;
        margin: 30px;
        &--btn {
            background-color: #071833;
            padding: 0.5rem 2rem;
            color: #fff;
            font-size: 20px;
            border: none;
            cursor: pointer;
            border-radius: 8px;
        }
    }
}

@media screen and (max-width: 1200px) {
    .matchesPage {
        &__matchCards {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
@media screen and (max-width: 950px) {
    .matchesPage {
        text-align: center;
        &__matchCards {
            justify-items: center;
            grid-template-columns: repeat(2, 1fr);
        }
    }
}
@media screen and (max-width: 1096px) {
    .matchesPage {
        &__header {
            display: flex;
            &__header1 {
                &--undefined,
                &--SchoolTournament {
                    margin-top: 100px;
                    margin-right: 50px;
                }
                &--LeagueTournament,
                &--KnockoutTournament {
                    color: #fff;
                    margin-top: 6%;
                    font-size: 50px;
                    margin-left: 55px;
                    letter-spacing: 3px;
                    margin-right: 10px;
                }
                height: 350px;
                width: 100%;
            }
            &__header2 {
                display: none;
            }
        }
        &__matchCards {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}
@media screen and (max-width: 912px) {
    .matchesPage {
        text-align: center;
        &__header {
            &__header1 {
                height: 560px;
                &--img {
                    margin-left: auto;
                    margin-right: auto;
                    margin-top: 7%;
                    width: 160px;
                    height: 160px;
                    border-radius: 10px;
                }
                &--undefined,
                &--SchoolTournament {
                    margin-top: -2rem;
                    margin-right: 50px;
                    font-size: 50px;
                }
                &--LeagueTournament,
                &--KnockoutTournament {
                    color: #fff;
                    margin-top: -10px;
                    font-size: 45px;
                    margin-left: 55px;
                    letter-spacing: 3px;
                    margin-right: 10px;
                }
                display: grid;
            }
        }
    }
}

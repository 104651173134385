.scorerEdit {
    width: 60%;
    height: 75%;
    border-radius: 10px;
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translate(-50%, 0%);
    bottom: 40px;
    background-color: #fff;
    overflow-y: scroll;
    &__overlay {
        background-color: #131313;
    }
    .scorerEditForm {
        &__general,
        &__personalData,
        &__matchData {
            &__header {
                display: flex;
                justify-content: space-between;
                &--text {
                    margin-top: 2%;
                    margin-left: 3%;
                    font-family: 'Poppins';
                    font-weight: 400;
                    color: #131313;
                }
                &--iconBtn {
                    color: red;
                    font-size: 29px;
                    margin-top: 27px;
                    margin-right: 27px;
                    cursor: pointer;
                    border: none;
                    background-color: #fff;
                    padding: 0;
                }
                &--dataDownload {
                    color: #071833;
                    font-size: 29px;
                    margin-right: 20px;
                }
            }
            &--input {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
            }
        }
        &__general {
            &--avatar,
            &--avatarOverlay {
                width: 150px;
                height: 150px;
                margin-top: 3%;
                margin-left: 43%;
                cursor: pointer;
                border-radius: 50%;
            }
            &--avatarOverlay {
                position: relative;
                background: #0000009b;
                margin-top: -153px;
                transition: 0.6s ease;
                opacity: 0;

                &:hover {
                    opacity: 1;
                }
                .editIcon {
                    padding: 70px;
                    color: #fff;
                    font-size: 30px;
                    cursor: pointer;
                    margin-left: -5px;
                }
            }
            &--uploadBtn {
                position: absolute;
                display: none;
            }
        }
        &__btn {
            display: flex;
            margin-left: 3%;
            margin-top: 2%;
            margin-bottom: 2%;
            &--cancel,
            &--submit {
                font-size: 14px;
                color: #fff;
                margin-right: 0.8rem;
                font-family: 'Poppins';
                padding: 0.4rem 1.2em;
                border: none;
                font-weight: 600;
                cursor: pointer;
                text-align: center;
                border-radius: 10px;
            }
            &--submit {
                background-color: #3d26d6;
                &:hover {
                    background-color: #3d26d6;
                }
            }
            &--cancel {
                background-color: #131313;
                &:hover {
                    background-color: #3a3a3a;
                }
            }
        }
    }
}
.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.541);
}
@media screen and (max-width: 799px) {
    .scorerEdit {
        width: 90%;
        .scorerEditForm {
            .input_box {
                max-width: 400px;
            }
            &__general {
                &--avatar,
                &--avatarOverlay {
                    margin-left: 32%;
                }
            }
            &__general,
            &__personalData,
            &__matchData {
                &--header {
                    text-align: center;
                }
            }
            &__btn {
                display: grid;
                &--submit {
                    margin-top: 4%;
                    margin-bottom: 4%;
                }
            }
        }
    }
}

.teamsPage {
    font-family: 'Poppins';
    &__header {
        display: flex;
        &__header1 {
            &--text {
                color: #fff;
                margin-top: 150px;
                font-size: 70px;
                margin-left: 55px;
                letter-spacing: 3px;
            }
            &--img {
                margin-left: 55px;
                margin-top: 7%;
                width: 200px;
                height: 200px;
                border-radius: 10px;
            }
            display: flex;
            background-color: #061227;
            height: 400px;
            width: 65%;
        }
        &__header2 {
            background-color: #0d4194;
            height: 400px;
            width: 35%;
        }
    }
    &__teamSelect {
        display: flex;
    }
    &__teamSelect select {
        background-color: #071833;
        color: #fff;
        border: none;
        font-size: 18px;
        border-radius: 5px;
        padding: 0.4rem 0.5rem;
        margin: 25px 0 0 20px;
        font-family: 'Poppins';
        justify-content: center;
        cursor: pointer;
        text-transform: uppercase;
    }
    &__pagination {
        text-align: center;
        margin: 30px;
        &--btn {
            margin-left: 30px;
            background-color: #071833;
            padding: 0.5rem 2rem;
            color: #fff;
            font-size: 20px;
            border: none;
            cursor: pointer;
            border-radius: 8px;
        }
    }
    &__pointsTable {
        font: bold 16px 'Poppins';
        cursor: pointer;
        border: none;
        background-color: #071833;
        color: #fff;
        padding: 0.5rem 0.5rem;
        border-radius: 2px;
        text-transform: uppercase;
        margin-left: 20px;
    }
    &__teamCards {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: auto;
    }
}
@media screen and (max-width: 1200px) {
    .teamsPage {
        &__teamCards {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
@media screen and (max-width: 900px) {
    .teamsPage {
        text-align: center;
        &__teamCards {
            justify-items: center;
            grid-template-columns: repeat(2, 1fr);
        }
    }
}
@media screen and (max-width: 695px) {
    .teamsPage {
        &__header {
            display: flex;
            &__header1 {
                &--text {
                    margin-top: 100px;
                    margin-right: 50px;
                }
                height: 350px;
                width: 100%;
            }
            &__header2 {
                display: none;
            }
        }
        &__teamCards {
            grid-template-columns: repeat(1, 1fr);
            margin-right: 20px;
        }
    }
}
@media screen and (max-width: 1023px) {
    .teamsPage {
        text-align: center;
        &__header {
            &__header1 {
                &--img {
                    margin-left: auto;
                    margin-right: auto;
                    margin-top: 7%;
                    width: 160px;
                    height: 160px;
                    border-radius: 10px;
                }
                &--text {
                    margin-top: 10px;
                    margin-right: 50px;
                    font-size: 50px;
                }
                display: grid;
            }
        }
    }
}

/***** For Smartphones *******/
.forgetPass {
    width: 85%;
    .container {
        position: absolute;
        left: 50%;
        height: auto;
        background-color: transparent;
        -webkit-transition: all 0.1s;
        transition: all 0.1s;
        bottom: 50%;
        -webkit-transform: translateX(-50%) translateY(50%);
        transform: translateX(-50%) translateY(50%);

        &__form {
            width: 100%;
            overflow: hidden;
            background-color: #fefefe;
            padding: 21px 13px;
            border-radius: 21px;
            -webkit-box-shadow: 0px 5px 34px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 5px 34px rgba(0, 0, 0, 0.1);
            h4 {
                text-align: center;
                color: black;
                font-size: 1.1em;
                font-family: times;
                font-style: normal;
                line-height: 130%;
                opacity: 0.6;
            }
            &--logo {
                width: 150px;
                margin-left: 35%;
            }
            &__formGroup {
                position: relative;
                width: 100%;
                display: block;
                margin: 1em 0;
                font-size: 1em;

                input {
                    width: 100%;
                    border: none;
                    border-bottom: 1px solid #888888;
                    padding: 8px 0;
                    font-size: inherit !important;
                    margin-bottom: 13px;
                    outline: none;
                    opacity: 0.7;
                    font-family: sans-serif;
                    font-weight: 600;
                    &:focus {
                        opacity: 1;
                        border-bottom: 2px solid #1436f7;
                        color: #1436f7;
                        + {
                            label {
                                left: 500px;
                                opacity: 0;
                            }
                        }
                        ~ {
                            span {
                                left: 0;
                                opacity: 1;
                            }
                        }
                    }
                }
                label {
                    font-family: sans-serif;
                    position: absolute;
                    font-size: 0.8em;
                    top: -1.4rem;
                    left: 0;
                    -webkit-transition: all 0.3s;
                    transition: all 0.3s;
                    opacity: 0.7;
                    color: #888888;
                    text-transform: uppercase;
                }
                span {
                    font-family: sans-serif;
                    position: absolute;
                    top: -1em;
                    left: -500px;
                    opacity: 0;
                    color: #333333;
                    font-weight: bold;
                    text-transform: uppercase;
                    font-size: 0.8em;
                    -webkit-transition: all 0.3s;
                    transition: all 0.3s;
                }
            }
            &--loginBtn {
                cursor: pointer;
                border: none;
                color: #fefefe;
                padding: 0.8em 0;
                font-size: 1em;
                font-weight: 300;
                width: 100%;
                border-radius: 55px;
                -webkit-box-shadow: 0px 3px 21px rgba(122, 140, 190, 0.7);
                box-shadow: 0px 3px 21px rgba(122, 140, 190, 0.7);
                background: -webkit-gradient(linear, left top, right top, from(#336cc0), to(#0e0750));
                background: linear-gradient(to right, #336cc0, #0e0750);
                background-size: 100%;
                text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
            }
        } // p {
        //     color: white;
        //     text-align: center;
        // }
        // p a {
        //     .container {
        //         width: 70%;
        //     }

        //     color: inherit;
        //     text-decoration: none;
        //     font-weight: bold;
        // }
    }
}

.social {
    margin-top: 1.8em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

/***** For Tablets *******/
@media screen and (min-width: 480px) {
    .forgetPass {
        .container {
            width: 70%;
            &__form {
                &--loginBtn {
                    padding: 0.8em 0;
                    font-size: 1.2em;
                }
            }
        }
    }
}
/***** For Desktop Monitors *******/
@media screen and (min-width: 768px) {
    .forgetPass {
        .container {
            width: 500px;
        }
    }
}

.playerAdd {
    width: 60%;
    height: 75%;
    border-radius: 10px;
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translate(-50%, 0%);
    bottom: 40px;
    background-color: #fff;
    font-family: 'Poppins';
    overflow-y: scroll;
    &__overlay {
        background-color: #131313;
    }
    .playerAddForm {
        &__general,
        &__personalData,
        &__stats {
            &--header {
                margin-left: 2%;

                font-weight: 400;
                color: #131313;
            }
            &--input {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
            }
        }
        &__general {
            &--avatar,
            &--avatarOverlay {
                width: 150px;
                height: 150px;
                margin-top: 3%;
                margin-left: 43%;
                cursor: pointer;
                border-radius: 50%;
            }
            &--uploadBtn {
                position: absolute;
                display: none;
            }
            &--avatarOverlay {
                position: relative;
                background: #0000009b;
                margin-top: -156px;
                transition: 0.6s ease;
                opacity: 0;

                &:hover {
                    opacity: 1;
                }
                .editIcon {
                    padding: 70px;
                    color: #fff;
                    font-size: 30px;
                    cursor: pointer;
                    margin-left: -5px;
                }
            }
        }

        &__stats {
            &--header1,
            &--header2 {
                margin-left: 2%;
                font-weight: 400;
                margin-top: 1%;
                color: #131313;
                font-size: 27px;
            }
        }
        &__btn {
            display: flex;
            margin-left: 2%;
            margin-top: 2%;
            margin-bottom: 2%;
            &--cancel,
            &--submit {
                font-size: 14px;
                color: #fff;
                margin-right: 0.8rem;
                font-family: 'Poppins';
                padding: 0.4rem 1.2em;
                border: none;
                font-weight: 600;
                cursor: pointer;
                text-align: center;
                border-radius: 10px;
            }
            &--submit {
                background-color: #3d26d6;
                &:hover {
                    background-color: #3d26d6;
                }
            }
            &--cancel {
                background-color: #131313;
                &:hover {
                    background-color: #3a3a3a;
                }
            }
        }
    }
    .upload-btn-wrapper {
        position: relative;
        overflow: hidden;
        display: inline-block;
    }
    .aadharBtn,
    .pdfBtn {
        color: rgb(255, 255, 255);
        background-color: #071833;
        padding: 8px 20px;
        border-radius: 8px;
        font-size: 16px;
        font-weight: bold;
        margin: 0 0 20px 20px;
    }
    input[type='file'] {
        font-size: 20px;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
    }
}

@media screen and (max-width: 799px) {
    .playerAdd {
        width: 90%;

        .playerAddForm {
            .input_box {
                max-width: 400px;
            }
            .selectInputBox {
                max-width: 400px;
            }
            &__general,
            &__personalData,
            &__stats {
                &--header,
                &--header1,
                &--header2 {
                    text-align: center;
                }
            }
            &__general {
                &--avatar,
                &--avatarOverlay {
                    margin-left: 34%;
                }
            }
            &__btn {
                display: grid;
                &--submit {
                    margin-top: 4%;
                    margin-bottom: 4%;
                }
            }
        }
    }
}

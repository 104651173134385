.matchCardHome {
    color: #fff;
    display: grid;
    background-color: #0d4194;
    width: 320px;
    height: 310px;
    margin-top: 25px;
    margin-left: 20px;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.39);
    transition: 0.3s;
    font-family: 'Poppins';
    &__header {
        text-align: center;
        margin-top: 10px;
        &--title {
            font-size: 18px;
        }
        &--img {
            margin-top: 4px;
            margin-bottom: -4px;
        }
    }
    &__container {
        display: flex;
        justify-content: space-around;
        margin-top: 10px;
        &--label {
            text-transform: uppercase;
            font-size: 12px;
        }
        &--text {
            font-size: 15px;
            margin-bottom: 12px;
        }
        &--status {
            text-align: center;
            padding: 0 20px;
            margin-bottom: 10px;
        }
        &__column2,
        &__column1 {
            width: 150px;
        }
    }
}

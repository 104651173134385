.umpiresOverview {
    &__umpireAddBtn {
        background-color: transparent;
        border: 2px solid #071833;
        border-radius: 4px;
        color: #071833;
        cursor: pointer;
        font-size: 14px;
        margin: 50px 20px 20px 20px;
        padding: 0.7rem 1em;
        text-align: center;
        text-transform: uppercase;
        font-family: sans-serif;
        font-weight: 600;
        transition: box-shadow 100ms ease-in-out, color 100ms ease-in-out;
        &:hover {
            box-shadow: 0 0 40px 40px #071833 inset;
            color: #fff;
            outline: 0;
        }
    }
    &__umpireCard {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: auto;
        margin-bottom: 80px;
    }
    &__dataDownload {
        font: bold 16px 'Poppins';
        text-decoration: none;
        background-color: #071833;
        color: #fff;
        padding: 0.6rem 0.5rem;
        border-radius: 2px;
        text-transform: uppercase;
    }
}

@media screen and (max-width: 1250px) {
    .umpiresOverview {
        &__umpireCard {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
@media screen and (max-width: 950px) {
    .umpiresOverview {
        text-align: center;
        &__umpireCard {
            grid-template-columns: repeat(2, 1fr);
            justify-items: center;
        }
    }
}
@media screen and (max-width: 695px) {
    .umpiresOverview {
        &__umpireCard {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}

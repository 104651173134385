.teamPlayersOverview {
    font-family: 'Poppins';
    &__header {
        display: flex;
        &__header1 {
            &--text {
                color: #fff;
                margin-top: 150px;
                font-size: 70px;
                margin-left: 55px;
                letter-spacing: 3px;
            }
            background-color: #071833;
            height: 400px;
            width: 65%;
        }
        &__header2 {
            background-color: #0d4194;
            height: 400px;
            width: 35%;
        }
    }
    &__playerAddBtn {
        background-color: transparent;
        border: 2px solid #071833;
        border-radius: 4px;
        color: #071833;
        cursor: pointer;
        font-size: 14px;
        margin: 50px 20px 20px 20px;
        padding: 0.7rem 1em;
        text-align: center;
        text-transform: uppercase;
        font-family: sans-serif;
        font-weight: 600;
        transition: box-shadow 100ms ease-in-out, color 100ms ease-in-out;
        &:hover {
            box-shadow: 0 0 40px 40px #071833 inset;
            color: #fff;
            outline: 0;
        }
    }

    &__playerCard {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-auto-rows: auto;
        margin-bottom: 80px;
    }
    h2 {
        margin: 20px 0px 0px 20px;

        font-size: 25px;
        color: #071833;
    }
    &__dataDownload {
        font: bold 16px 'Poppins';
        text-decoration: none;
        background-color: #071833;
        color: #fff;
        padding: 0.6rem 0.5rem;
        border-radius: 2px;
        text-transform: uppercase;
    }
}
@media screen and (max-width: 1599px) {
    .teamPlayersOverview {
        &__playerCard {
            grid-template-columns: repeat(4, 1fr);
        }
    }
}
@media screen and (max-width: 1400px) {
    .teamPlayersOverview {
        &__playerCard {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
@media screen and (max-width: 1050px) {
    .teamPlayersOverview {
        text-align: center;
        &__playerCard {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}
@media screen and (max-width: 695px) {
    .teamPlayersOverview {
        &__header {
            display: flex;
            &__header1 {
                &--text {
                    margin-top: 100px;
                    margin-right: 50px;
                }
                height: 350px;
                width: 100%;
            }
            &__header2 {
                display: none;
            }
        }
        &__playerCard {
            grid-template-columns: repeat(1, 1fr);
            justify-items: center;
        }
    }
}

.playerView {
    width: 90%;
    height: 90%;
    border-radius: 10px;
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translate(-50%, 0%);
    bottom: 40px;
    background-color: #fff;
    overflow-y: scroll;
    font-family: 'Poppins';
    text-align: center;
    color: rgb(71, 71, 71);
    &__modalClose {
        font-size: 30px;
        text-align: right;
        margin: 3px;
        display: none;
    }
    &__stats {
        width: 90%;
        max-width: 1000px;
        margin: 1rem auto;
    }
    &__teamName {
        font-size: 40px;
        margin-top: 30px;
        margin-bottom: 10px;
    }
    &__teamLogo {
        width: 150px;
        height: 150px;
        border-radius: 50%;
    }
    &__items {
        width: 100%;

        background-color: #f4f4fd;
        display: flex;

        border-radius: 0.5rem;
        &__image {
            width: 300px;
            height: 350px;
            background-color: #4562ba;
            border-radius: 0.5rem;

            &--img {
                width: 200px;
                height: 200px;
                margin: 20% auto;
                border-radius: 10px;
                box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
            }
        }
        &__body {
            display: grid;
            grid-template-columns: repeat(2, minmax(150px, 1fr));
            grid-auto-rows: auto;
            &__battingStats,
            &__bowlingStats {
                margin-left: 70px;
                h1 {
                    margin: 7px 0;
                }
            }
        }
    }
}
@media screen and (max-width: 1153px) {
    .playerView {
        &__teamName {
            font-size: 30px;
        }
        &__modalClose {
            display: block;
        }
        &__items {
            display: grid;
            &__image {
                width: 100%;
                height: 200px;

                &--img {
                    width: 140px;
                    height: 140px;
                    margin-top: 30px;
                }
            }
            &__body {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));

                &__battingStats,
                &__bowlingStats {
                    margin-left: 0;
                    h1 {
                        margin: 7px 0;
                        font-size: 26px;
                    }
                }
            }
        }
    }
}

.groundCard {
    color: rgb(255, 255, 255);
    display: grid;
    background-color: #0d4194;
    width: 300px;
    height: 245px;
    margin-left: 20px;
    margin-top: 25px;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.39);
    transition: 0.3s;
    font-family: 'Poppins';

    &__container {
        text-align: center;
        margin-top: 25px;
        &--label {
            text-transform: uppercase;
            font-size: 15px;
        }
        &--text {
            font-size: 20px;
            margin-bottom: 8px;
            font-weight: 800;
        }
    }
}

.aboutUsPage {
    font-family: 'Poppins';

    &__header {
        display: flex;
        &__header1 {
            &--text {
                color: #fff;
                margin-top: 150px;
                font-size: 70px;
                margin-left: 55px;
                letter-spacing: 3px;
            }
            &--img {
                margin-left: 55px;
                margin-top: 7%;
                width: 200px;
                height: 200px;
                border-radius: 10px;
            }
            display: flex;
            background-color: #061227;
            height: 400px;
            width: 65%;
        }
        &__header2 {
            background-color: #0d4194;
            height: 400px;
            width: 35%;
        }
    }
    &__container {
        width: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-attachment: fixed;
        &__header,
        &__footer {
            text-align: center;
            padding-top: 20px;
            padding-bottom: 20px;
            &--quote {
                margin-top: 20px;
                font-size: 24px;
                color: #0d4194;
            }
        }
        &--content {
            padding: 0 110px;
            font-size: 21.4px;
            line-height: 40px;
        }
    }
}
@media screen and (max-width: 910px) {
    .aboutUsPage {
        text-align: center;
        &__header {
            &__header1 {
                &--text {
                    margin-top: 100px;
                    margin-right: 50px;
                }
                height: 350px;
                width: 100%;
            }
            &__header2 {
                display: none;
            }
        }

        &__container {
            // &__header,
            // &__footer {
            //     text-align: center;
            //     padding-top: 20px;
            //     padding-bottom: 20px;
            //     &--quote {
            //         margin-top: 20px;
            //         font-size: 24px;
            //         color: #0d4194;
            //     }
            // }
            &--content {
                padding: 0 20px;
                font-size: 21.4px;
                line-height: 40px;
            }
        }
    }
}
@media screen and (max-width: 666px) {
    .aboutUsPage {
        text-align: center;
        &__header {
            &__header1 {
                &--img {
                    margin-left: auto;
                    margin-right: auto;
                    margin-top: 7%;
                    width: 150px;
                    height: 150px;
                    border-radius: 10px;
                }
                &--text {
                    margin-top: 10px;
                    margin-right: 50px;
                    font-size: 50px;
                }
                display: grid;
            }
        }
    }
}

.matchEdit {
    width: 60%;
    height: 75%;
    border-radius: 10px;
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translate(-50%, 0%);
    bottom: 40px;
    background-color: #fff;
    overflow-y: scroll;
    .matchEditForm {
        &__matchData,
        &__feeStatus {
            &__header {
                display: flex;
                justify-content: space-between;
                &--text {
                    margin-top: 1%;
                    margin-left: 2%;
                    font-family: 'Poppins';
                    font-weight: 400;
                    color: #131313;
                }
                &--iconBtn {
                    color: rgb(236, 39, 39);
                    font-size: 29px;
                    margin-top: 27px;
                    margin-right: 27px;
                    cursor: pointer;
                    border: none;
                    background-color: #fff;
                    padding: 0;
                }
            }
            &--input {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
                margin-left: 1%;
            }
        }
        &__btn {
            display: flex;
            margin-left: 2%;
            margin-top: 2%;
            margin-bottom: 2%;
            &--cancel,
            &--submit {
                font-size: 14px;
                color: #fff;
                margin-right: 0.8rem;
                font-family: 'Poppins';
                padding: 0.4rem 1.2em;
                border: none;
                font-weight: 600;
                cursor: pointer;
                text-align: center;
                border-radius: 10px;
            }
            &--submit {
                background-color: #3d26d6;
                &:hover {
                    background-color: #3d26d6;
                }
            }
            &--cancel {
                background-color: #131313;
                &:hover {
                    background-color: #3a3a3a;
                }
            }
        }
    }
    .matchDelete {
        width: 30%;
        height: 10%;
        border-radius: 10px;
        position: relative;
        top: 200px;
        left: 50%;
        transform: translate(-50%, 0%);
        bottom: 40px;
        background-color: #fff;
        &__btn {
            display: flex;
            margin-left: 2%;
            margin-top: 2%;
            margin-bottom: 2%;
            &--cancel,
            &--submit {
                font-size: 14px;
                color: #fff;
                margin-right: 0.8rem;
                font-family: 'Poppins';
                padding: 0.4rem 1.2em;
                border: none;
                font-weight: 600;
                cursor: pointer;
                text-align: center;
                border-radius: 10px;
            }
            &--submit {
                background-color: #3d26d6;
                &:hover {
                    background-color: #3d26d6;
                }
            }
            &--cancel {
                background-color: #131313;
                &:hover {
                    background-color: #3a3a3a;
                }
            }
        }
    }
}
.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.541);
}
@media screen and (max-width: 799px) {
    .matchEdit {
        width: 90%;

        .matchEditForm {
            text-align: center;
            &__matchData,
            &__feeStatus {
                .input_box {
                    max-width: 400px;
                }
                .selectInputBox {
                    max-width: 400px;
                }
            }
            &__btn {
                display: grid;
                &--submit {
                    margin-top: 4%;
                    margin-bottom: 4%;
                }
            }
        }
    }
}

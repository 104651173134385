.matchesOverview {
    font-family: 'Poppins';
    &__matchAddBtn {
        background-color: transparent;
        border: 2px solid #071833;
        border-radius: 4px;
        color: #071833;
        cursor: pointer;
        font-size: 14px;
        margin-top: 50px;
        margin-left: 20px;
        padding: 0.7rem 1rem;
        text-align: center;
        text-transform: uppercase;

        font-weight: 600;
        transition: box-shadow 100ms ease-in-out, color 100ms ease-in-out;
        &:hover {
            box-shadow: 0 0 40px 40px #071833 inset;
            color: #fff;
        }
    }
    &__matchCard {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: auto;
        margin-bottom: 80px;
    }
    &__pagination {
        text-align: center;
        margin: 30px;
        &--btn {
            background-color: #071833;
            padding: 0.5rem 2rem;
            color: #fff;
            font-size: 20px;
            border: none;
            cursor: pointer;
            border-radius: 8px;
        }
    }
    &__matchSelect select {
        background-color: #071833;
        color: #fff;
        border: none;
        font-size: 18px;
        border-radius: 5px;
        padding: 0.4rem 0.5rem;
        margin: 20px;
        font-family: 'Poppins';
        justify-content: center;
        cursor: pointer;
        text-transform: uppercase;
    }
    &__dataDownload,
    &__oldMatches {
        font: bold 16px 'Poppins';
        text-decoration: none;
        background-color: #071833;
        color: #fff;
        padding: 0.6rem 0.5rem;
        border-radius: 2px;
        text-transform: uppercase;
        margin-left: 20px;
    }
}
@media screen and (max-width: 1250px) {
    .matchesOverview {
        &__matchCard {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
@media screen and (max-width: 950px) {
    .matchesOverview {
        text-align: center;
        &__matchCard {
            grid-template-columns: repeat(2, 1fr);
            justify-items: center;
        }
    }
}
@media screen and (max-width: 695px) {
    .matchesOverview {
        &__matchCard {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}

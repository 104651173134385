.teamsOverview {
    &__teamAddBtn {
        background-color: transparent;
        border: 2px solid #071833;
        border-radius: 4px;
        color: #071833;
        cursor: pointer;
        font-size: 14px;
        margin: 50px 20px 20px 20px;
        padding: 0.7rem 1em;
        text-align: center;
        text-transform: uppercase;
        font-family: sans-serif;
        font-weight: 600;
        transition: box-shadow 100ms ease-in-out, color 100ms ease-in-out;
        &:hover {
            box-shadow: 0 0 40px 40px #071833 inset;
            color: #fff;
            outline: 0;
        }
    }
    &__teamCard {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: auto;
        margin-bottom: 80px;
    }
    &__pagination {
        text-align: center;
        margin: 30px;
        &--btn {
            margin-left: 30px;
            background-color: #071833;
            padding: 0.5rem 2rem;
            color: #fff;
            font-size: 20px;
            border: none;
            cursor: pointer;
            border-radius: 8px;
        }
    }
    &__teamSelect {
        display: flex;
    }
    &__teamSelect select {
        background-color: #071833;
        color: #fff;
        border: none;
        font-size: 18px;
        border-radius: 5px;
        padding: 0.4rem 0.5rem;

        margin-left: 20px;
        font-family: 'Poppins';
        justify-content: center;
        cursor: pointer;
        text-transform: uppercase;
    }
    &__dataDownload {
        font: bold 16px 'Poppins';
        text-decoration: none;
        background-color: #071833;
        color: #fff;
        padding: 0.6rem 0.5rem;
        border-radius: 2px;
        text-transform: uppercase;
    }
    &__pointsTable {
        font: bold 16px 'Poppins';
        cursor: pointer;
        border: none;
        background-color: #071833;
        color: #fff;
        padding: 0.5rem 0.5rem;
        border-radius: 2px;
        text-transform: uppercase;
        margin-left: 20px;
    }
}

@media screen and (max-width: 1200px) {
    .teamsOverview {
        &__teamCard {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
@media screen and (max-width: 900px) {
    .teamsOverview {
        text-align: center;
        &__teamCard {
            justify-items: center;
            grid-template-columns: repeat(2, 1fr);
        }
    }
}
@media screen and (max-width: 695px) {
    .teamsOverview {
        &__teamCard {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}

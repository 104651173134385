.feeds {
    &__NewsAddBtn,
    &__VideoAddBtn,
    &__PhotoAddBtn {
        background-color: transparent;
        border: 2px solid #071833;
        border-radius: 4px;
        color: #071833;
        cursor: pointer;
        font-size: 14px;
        margin: 20px 20px 20px 20px;
        padding: 0.7rem 1em;
        text-align: center;
        text-transform: uppercase;
        font-family: sans-serif;
        font-weight: 600;
        transition: box-shadow 100ms ease-in-out, color 100ms ease-in-out;
        &:hover {
            box-shadow: 0 0 40px 40px #071833 inset;
            color: #fff;
        }
    }
    .videoCards {
        max-width: 1200px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
    .tabs-titles {
        list-style: none;
        padding: 0px;
        margin: 0;
    }

    .tab-title {
        font-family: 'Poppins';
        background-color: #fff;
        display: inline-block;
        padding: 10px;
        color: #c7c6c2;
        cursor: pointer;
        margin-left: 1px;
        font-size: 21zpx;
        font-weight: 800;
    }

    .tab-title--active {
        background-color: #f5f5f5;
        color: #071833;
    }

    .tab-content {
        background-color: #f5f5f5;
        padding: 5px;
        margin: 0;
    }
    .upload {
        display: flex;
        margin-top: 20px;
        margin-left: 20px;
        .upload-btn-wrapper {
            position: relative;
            overflow: hidden;
            display: inline-block;

            .photoBtn {
                color: rgb(255, 255, 255);
                background-color: #0d4194;
                padding: 8px 20px;
                border-radius: 8px;
                font-size: 16px;
                font-weight: bold;
                margin: 0 0 20px 20px;
            }
            input[type='file'] {
                font-size: 20px;
                position: absolute;
                left: 0;
                top: 0;
                opacity: 0;
            }
        }
        &--title {
            font-size: 25px;
            font-family: 'Poppins';
            font-weight: 500;
        }
        &--view {
            font-family: 'Poppins';
            margin: 6.5px;
            margin-left: 20px;
            color: #0d4194;
            font-size: 20px;
            font-weight: 500;
        }
    }
    .submit {
        color: rgb(255, 255, 255);
        background-color: black;
        padding: 8px 20px;
        border-radius: 8px;
        font-size: 16px;
        font-weight: bold;
        margin: 0 0 20px 20px;
    }
    .submit {
        background-color: black;
    }
    .photoGallery {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }
}
@media (max-width: 1375px) {
    .feeds {
        .videoCards {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@media (max-width: 900px) {
    .feeds {
        .videoCards {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
        }
    }
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
* {
    margin: 0;
    padding: 0;
}
.nav {
    width: 100%;
    background: #071833;
    height: 80px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    font-family: Poppins;
    z-index: 1;
    &__menu-bars {
        margin-left: 2rem;
        font-size: 2rem;
        background: none;
        color: #fff;
        transition: all 2 linear;
        border: none;
        cursor: pointer;
    }
    &__header {
        display: flex;
        align-items: center;
        &--logo {
            width: 50px;
            margin-left: 1rem;
        }
        &--text,
        &--textAbbr {
            color: rgb(255, 255, 255);
            margin-left: 1rem;
            font-size: 20px;
            font-weight: 00;
        }
        &--textAbbr {
            display: none;
        }
    }
    &__body {
        display: flex;
        margin-right: 2rem;
    }
    &__link,
    &__btn {
        text-align: center;
        color: #fff;
        font-family: Poppins;
        text-decoration: none;
        margin: 0 1rem;
        height: 100%;
        cursor: pointer;
        transition-duration: 0.2s;
        &--dropdown {
            color: black;
            padding: 12px 16px;
            text-decoration: none;
            display: block;
            :hover {
                background-color: #ddd;
            }
        }
    }
    &__headertxt {
        text-decoration: none;
    }

    &__link:hover {
        text-decoration: underline;
    }
    &__btn {
        background-color: #fff;
        padding: 0.4rem 1.2rem 0.4rem 1.2rem;
        border: 1px solid white;
        text-align: center;
        margin-left: 1rem;
        color: rgb(0, 0, 0);
    }
    &__btn:hover {
        border: none;
        background-color: #0d4194;
        color: rgb(255, 255, 255);
    }
    .hamburger {
        display: none;
        color: #fff;
        background: none;
        border: none;
    }
}
.drop {
    position: relative;
    display: inline-block;
    &__dropdown {
        display: block;
        position: absolute;
        background-color: #f1f1f1;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
    }

    :hover {
        opacity: 1;
    }
}
.dropbtn {
    color: white;
    text-decoration: none;
    cursor: pointer;
}
.dropdown {
    position: relative;
    display: inline-block;
}
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 180px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}
.dropdown-content {
    .link {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
    }
}

.dropdown-content .link:hover {
    background-color: #ddd;
}

.dropdown:hover .dropdown-content {
    display: block;
}
.dropdown:hover .dropbtn {
    text-decoration: underline;
}
@media screen and (max-width: 1222px) {
    .nav {
        .hamburger {
            color: #fff;
            display: block;
            position: absolute;
            padding-right: 20px;
            top: -5px;
            transform: translate(-100%, 75%);
            font-size: 1.8rem;
            cursor: pointer;
        }
        &__body {
            display: none;
        }
        &__header {
            &--text {
                display: none;
            }
            &--textAbbr {
                display: block;
            }
        }
        &__responsive {
            display: block;
            background-color: #071833;
            width: 100%;
            position: absolute;
            top: 80px;
            flex-direction: column;
            padding-top: 2rem;
            padding-bottom: 2rem;
            text-align: center;
            z-index: 1;
        }
        .item {
            margin: 2rem;
        }
    }
}
